

html {
   --gradient-left: #70029D;
  --gradient-right: #C20280;
  
  background:  var( --gradient-right) ;
  
   /* Support for all WebKit browsers. */
   -webkit-font-smoothing: antialiased;
   /* Support for Safari and Chrome. */
   text-rendering: optimizeLegibility;
   /* Support for Firefox. */
   -moz-osx-font-smoothing: grayscale;
   /* Support for IE. */
   font-feature-settings: 'liga';

   
}



.str-chat {
  /*  position: relative; */
}

.str-chat__channel-list-team {
  background: linear-gradient(to top, var(--gradient-right) 75%, var(--gradient-left) 100%);
}
.str-chat__channel-list-team__main {
 
  background: linear-gradient(to top, var(--gradient-right) 75%, var(--gradient-left) 100%);
 
  border-radius: 0px 30px 0px 0px;

  min-width: 21em !important;
  margin-top: 1rem !important;
}

.str-chat__load-more-button__button {
  border-radius: 0px !important;
  background: var(--gradient-left) !important;
  color:white !important;
  box-shadow:  2px 2px 5px 0 rgb(0 0 0 / 7%) !important;
}

.messaging.str-chat {
  background-color: white !important;
}

.str-chat__channel-preview-title {
  
  color: white !important;
}

.str-chat__channel-preview-last-message {
  color: white !important;
  font-style: italic;
}

.str-chat__channel-list-team__header {
 /*  color: white !important; */
 visibility: collapse !important;
}

.str-chat__channel-list-team__header--button {
  visibility: collapse  !important;
}


.str-chat__input-flat {
  padding-right: 10px !important;
}

.str-chat__reverse-infinite-scroll {
  padding-top: 0px !important;
}

.messaging.str-chat .str-chat__list {
  padding-top: 0px !important;
}


.str-chat__channel-preview-messenger--active {
  background-color: #DD2476 !important;
  border-radius: 0;
}

.str-chat__channel-preview-messenger :hover {
  
}



.str-chat__channel-preview-messenger--unread {
  background-color: #DD2476 !important;
  border-radius: 0;
}

.str-chat__channel-preview-messenger--right {
  width: 10rem;
}


.str-chat__channel-list-team__main {
  color: white !important;
  font-size: small;
}

.str-chat p {
  margin-left: 1rem !important;
}

.str-chat__header-livestream-left--members {
  color: #FFF !important;
}


.lottie_loader {

  background: linear-gradient(90deg, var(--gradient-left) 25%, var(--gradient-right) 100%);
  z-index: 5;
}

