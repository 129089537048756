

svg {
  display: inline-block;
  vertical-align: top;
}

img, svg, a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}


h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 24px 16px 16px 24px;
  display: inline-block;
  vertical-align: top;
  color: rgb(255, 255, 255);
  text-shadow: white;
}

button+button {
  margin-left: 10px;
}

button+button:hover,
button+button:focus {
  outline: none;
}

.header_icon {
  width: 64px;
  height: 64px;
}

.header {

  display: flex;
  justify-content: left;
  align-items: center;
  transition: 300ms;
  z-index: 1;

}

.main_logo_text {
  font-family: "Dillan", 'Helvetica Neue', Arial, sans-serif;
  color: white !important;
  font-size: 28px;
  text-shadow: 2px -6px 8px #49484833;
  z-index: 1;
}

.headerImage {
  width: 3rem;
  transition: 150ms;
  margin: 1rem;
  margin-right: 0.75rem;
  z-index: 1;
}


.scratch_profile_container {
  width: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin-top: -6rem;
  position: absolute;
  overflow: hidden;
  
}

.scratch_profile_image {
  margin: 5rem;
  border-radius: 0;
  width: 30rem;
  opacity: 0.2;
  z-index:-1;
}



.headerButtons {
   position: absolute; 

  top: 1rem;
  right: 1.5rem;

  z-index: 2;
  display: flex;
  justify-content: flex-end;
  column-gap: 1.5rem;
  align-items: center;

}



/* NOTIFICATIONS */

.alert {
  text-align: center;
  background-color: #FC466B;
  color: #fff;
  padding: 26px;
  z-index: 2;
  position: relative;

}

.alert p {
  margin: 0;
}

.alert button {
  background-color: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  
}

/* END NOTIFICATIONS */


@media only screen and (max-width: 800px) {

  .header {
    height: 4rem;
    
  }

  .headerButtons {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
  
  }


  .main_logo_text {
    font-size: 18px;
    text-shadow: 2px -6px 8px #49484833;
  }
  
  .headerImage {
    width: 1.5rem;
    margin: 0.75rem;
    margin-right: 0.5rem;
  
  }
 

 

  .scratch_profile_container {
    margin-top: -4rem;

  }

  .scratch_profile_image {
    margin: 3rem;
    width: 16rem;

  }
}

