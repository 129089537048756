@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css?family=Special+Elite");
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,600;1,600;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=IBM+Plex+Sans:ital,wght@1,200;1,300&display=swap');

@font-face {
    font-family: "Dillan";
    src: local("Dillan"), url("./fonts/Dillan.otf") format("opentype");
    font-weight: bold;

}

html {
    height: 100%;
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --main-text-color: #666666;
    --black-color: #666666;
    --footer-color: #fff;
    --gradient-left: #70029D;
    --gradient-right: #C20280;
    --bg-light: #FCF7FF;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    --border-radius: 16px;


    background: linear-gradient(90deg, var(--gradient-left) 0%, var(--gradient-right) 50%);



}

/* firefox remove white line in top of browser*/

#navigator-toolbox::after {
    border-bottom: 0px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */

body::-webkit-scrollbar {
    display: none;
}

.footer {

    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

a {
    color: var(--main-text-color);
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}


/* h2 {
    color: var(--black-color)
} */


.btn-tab, .btn-tab-reversed, .uploadcare--button_primary, .uploadcare--widget__button_type_open, .uploadcare--widget__button_type_open:hover, .uploadcare--widget__button_type_open:focus {
    background: linear-gradient(90deg, #ad0773 0%, var(--gradient-right) 50%);
}

.button {
    background: linear-gradient(90deg, var(--gradient-left) 25%, var(--gradient-right) 100%) !important;
    color: white !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #FFF;
}

/* Slider control   github.com/jpuri/react-range-slider */

.handle {
    background-color: var(--gradient-right) !important;
    border: 1px solid #FFF !important;
}

.track, .trackVertical {
    border: 1px solid #FFF !important;
}

.highlightedTrackStyle, .highlightedTrackVertical {
    background-color: #FFF !important;
    border: 10px solid var(--gradient-left) !important;
}

.attention_link {
    color: green;
    cursor: pointer;
}

.welcome_text {

    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    transition: 0.3s;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.welcome_text_title {
    font-family: "Dillan", 'Helvetica Neue', Arial, sans-serif;
    background: linear-gradient(90deg, var(--gradient-left) 25%, var(--gradient-right) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.welcome_text_card {
    max-width: 18rem;
    min-width: 10rem;
    padding: 1rem;
    padding-left: 2rem;
    padding-top: 0rem;
    text-align: left;
    color: var(--black-color);
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 1.44;
}



.gold_member_label {
    font-size: 11px;
    color: var(--gradient-right);
}

.gold_member_label_large {
    font-size: 13px;
    color: var(--gradient-right);
}

.welcome_contacts {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.44;
    text-align: center;
}

.footer_subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: var(--footer-color);
    font-size: 10px;
    padding: 0.5rem;
}

.footer_links {
    color: var(--footer-color);
    /* text-decoration: none; */
}

.header_slogan {
    font-family: 'Amatic SC';
    font-weight: 100;
    color: white;
    font-size: 100px;
    padding: 1rem;
    z-index: 1;
}

.main {
    display: flex;
    flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    position: relative;
    min-height: 0vh;

    margin-top: 0rem;
    background: white;
    border-radius: 35px;
    padding: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

.main_container {
    padding-left: 10%;
    padding-right: 10%;
}



.contact_list {
    background: var(--bg-light);
    padding: 2rem;
    border-radius: var(--border-radius);
    width: 100%;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    margin: 1rem;
    margin-top: 0rem;
}



.casino_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.casino {
    transition: 300ms;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
    padding: 3rem;
    padding-bottom: 2rem;
    width: 90%;
    text-align: center;

    background: url(./components/CASINO/casino_bg.webp)repeat;
    box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.3);
    background-repeat: repeat-xy;
    /* margin-bottom: -0.2rem; */

    border-radius: 16px 16px 0 0;


}

.glass {

    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);

    border-radius: var(--border-radius);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}


.casino_last_win_card {
    display: flex;
    column-gap: 0.5rem;
    margin-top: 1rem;
    background: white;
    border-radius: 20px;
    padding: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 5;


    font-weight: 500;
    background: linear-gradient(90deg, var(--gradient-left) 25%, var(--gradient-right) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.casino_cardbuttons_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0rem;
    top: 0rem;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 10;
    height: 14rem;
    width: 4rem;
}

.casino_cardbuttons {
    /*  transition: 0.2s; */
    width: 40px;
    height: 60px;
    position: relative;
    right: 1rem;
    top: 1.25rem;
    cursor: pointer;
}

.casino_cardnumber {
    position: absolute;
    top: 2rem;
    width: 100%;
    text-align: center;
}

.scratchcard {

    width: 420px;
    height: 420px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.4);
    cursor: default;
}



.casino_play_again {
    position: absolute;
    top: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 8rem;
    height: 8rem;
    padding: 1rem;
    border-radius: 100px;
    background-color: white;
    text-align: center;
    color: #222;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35), 0 0px 0px 10px rgba(254, 114, 75, 0.35);
    cursor: pointer;
    outline: none !important;
    border: none !important;
    -moz-outline-style: none !important;
}

.casino_win_popup {
    position: absolute;
    top: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 19rem;
    height: 19rem;
    padding: 1rem;
    border-radius: 250px;
    background-color: white;
    text-align: center;
    color: #222;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35), 0 0px 0px 10px rgba(254, 114, 75, 0.35);
    cursor: pointer;
    outline: none !important;
    border: none !important;
    -moz-outline-style: none !important;
    z-index: 5;
}

.popup_container {


    display: flex;
    align-items: center;
    justify-content: center;
     height: 100vh; 
    
}

.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 19rem;
    height: 19rem;
    padding: 1rem;
    border-radius: 250px;
    background-color: white;
    text-align: center;
    color: #222;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35), 0 0px 0px 10px rgba(254, 114, 75, 0.35);
    outline: none !important;
    border: none !important;
    -moz-outline-style: none !important;
    z-index: 6;
}


.casino_win_popop_button_container {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 19rem;
    width: 6rem;
    height: 3rem;
    padding: 0.25rem;
    padding-right: 1rem;
    background-color: white;
    border-radius: 150px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.popop_button_container {
    position: relative;
    top: 0rem;
    display: flex;
    justify-content: end;
    align-items: center;


    width: 4rem;
    height: 3rem;
    padding: 0.25rem;
    padding-right: 1rem;
    background-color: white;
    border-radius: 150px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.casino_infobutton {
    position: absolute;
    right: 1rem;
    top: 4.5rem;
}

.casino_multiplier {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 8.5rem;
    width: 16rem;
    height: 3rem;
    padding: 0.25rem;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}




.casino_miltiplier_button, .casino_miltiplier_button_selected {
    width: 3rem;
    height: 3rem;
    background: #EEE;

    color: #222;
    background: none;

    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;

    outline: none !important;

}

.noSelect, input, textarea, button, select, a, .casino_play_again {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
    outline: none !important;
}



.casino_miltiplier_button_selected {
    color: var(--gradient-right);
    font-weight: 700;

}

.casino_play_again_icon {
    margin-bottom: 0.2rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.settings_card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: white;
    margin-bottom: 1rem;
    max-width: 20rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.card_button {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    max-width: 6rem;
    margin: 1rem;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.card_button:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.profile_details_tab_buttons {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-content: center;
    position: relative;
    row-gap: 0px;
    margin-bottom: -1.05rem;
    margin-left: 1rem;
    z-index: 2;
    position: relative;
}

.profile_details_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;


    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
    max-width: 45rem;
    min-width: 20rem;
    min-height: 91vh;

}

.profile_details_card {
    margin: 1rem;
    margin-top: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    background-color: white;
    border-radius: var(--border-radius);
}

.info_button {
    cursor: 'pointer';
    opacity: 0.1;
}

.login_card {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    margin-top: -10rem;
    padding: 2rem;

    padding-bottom: 3rem;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    transition: 0.3s;

    background-color: white;
    border-radius: var(--border-radius);
}


.profile_details_blur {
    color: transparent;

    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 14rem;
    width: 14rem;
    padding: 1rem;
    position: absolute;
    border-radius: 200px;
    border: 1px solid;
    border-color: transparent;


    z-index: 1;
}

.profile_details_blur:hover {
    color: white;
    border-color: #777;
    background: #ff111111;
}

.user_card {
    transition: 0.2s;
    padding: 1rem;
    padding-top: 2rem;
    background-color: white;
    margin-bottom: 1rem;
    margin-top: 1rem;
    z-index: 1;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.15s 1;
    animation-fill-mode: forwards;

}

.user_card_empty_avatar {
    width: 8rem;
}

.user_card_empty_avatar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}


@media only screen and (min-width: 800px) {
    .user_card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 2rem;
        width: 40rem;

    }

    .main_container {
        padding-left: 10%;
        padding-right: 10%;
    }



    /*  .casino {
        width: 95%;
    } */
}

@media only screen and (min-width: 1400px) {
    .main_container {
        padding-left: 25%;
        padding-right: 25%;
    }
}



.profile_details_back {
    height: 6rem;
    width: 4rem;
    position: absolute;
    left: -2rem;
    margin-top: 1rem;
    align-content: flex-end;
    color: white;
    z-index: 2;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.profile_details_images {
    width: 100%;
    min-height: 10rem;
    min-width: 10rem;
    background-color: rgb(240, 240, 240);
}

.profile_detail_location {
    margin-top: 2rem;
    color: #ccc;
    text-align: left;
}

.profile_detail_description {
    color: #444444;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: large;
    text-align: justify;
}

.profile_detail_positive {
    background-image: linear-gradient(to right, #56ab2f 0%, #95c55a 51%, #56ab2f 100%);
}

.profile_detail_positive {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35);
    color: #ffffff;
    padding: 0.75rem;
    margin-top: 2rem;
    font-size: large;
    text-align: center;
}

.profile_detail_negative {
    background-image: linear-gradient(to right, #b40e24 0%, #ce112a 50%, #b40e24 100%);
}

.profile_detail_negative {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35);
    color: #ffffff;
    padding: 0.75rem;
    margin-top: 2rem;
    font-size: large;
    text-align: center;
}

.profile_image {
    max-width: 20rem;
    max-height: 20rem;
    min-height: 10rem;
    min-width: 10rem;
    height: 20vw;
    object-fit: cover;
}

.empty_profile_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: dashed;
    height: 25rem;
    outline-color: #DDD;
    transition: 0.3s;
    -moz-outline-radius: 8px;
    max-width: 20rem;
    min-width: 15rem;
    margin: 1rem;
    background-color: white;
    padding: 2rem;
    text-align: center;
    color: #CCC;
    border-radius: var(--border-radius);
}

.profile_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;


    max-width: 20rem;
    min-width: 15rem;
    min-height: 25rem;
    max-height: 25rem;
    margin: 1rem;
    background-color: white;
    padding: 2rem;
    text-align: center;
    overflow: hidden;
    animation: fadeIn 0.5s 1;
    animation-fill-mode: forwards;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: var(--border-radius);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.profile_card_new_banner {
    display: flex;
    justify-content: center;
    align-content: center;
    background: linear-gradient(90deg, var(--gradient-left) 0%, var(--gradient-right) 75%);
    color: white;
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 14px;
    padding: 0.25rem;

    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.profile_add_contact_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: var(--border-radius);
    min-width: 15rem;
    max-height: 25rem;
    margin: 1rem;
    background-color: white;
    padding: 2rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
    text-align: center;
    overflow: hidden;
    position: relative;
    height: 13rem;
    cursor: pointer;
}

.profile_add_contact_card:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.profile_add_contact_card:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: transparent var(--gradient-right) transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
}

.review_carroussel {
    width: 100%;
}

.review_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius);
    height: 28rem;
    margin: 1rem;

    padding: 2rem;
    text-align: center;


    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-position: center;
    background-size: cover;


}

.review_card_description {
    color: white;
    font-style: italic;
}

.review_card_title {
    font-size: 40px;
    font-weight: 500;
    background: linear-gradient(90deg, #e6a6ff 25%, #ffc7eb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

/* On mouse-over, add a deeper shadow */

.profile_card:hover {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.profile_card_location {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #ccc;
    text-align: left;
    min-height: 1.5rem;
}

.profile_card.ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
}

.match_text_gradient {
    font-size: 1em;
    margin-top: 2rem;
    letter-spacing: 3px;
    font-weight: 500;
    background: linear-gradient(90deg, var(--gradient-left) 25%, var(--gradient-right) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 15rem;
}

.text_gradient, .MuiTypography-body1 {
    font-weight: 500;
    background: linear-gradient(90deg, var(--gradient-left) 0%, var(--gradient-right) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 1px;
}

.text_without_gradient {
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 1px;
}

.contact_name_age_gradient {
    font-size: 1.5em;
    margin-top: 2rem;
    letter-spacing: 3px;
    font-weight: 400;
    background: linear-gradient(90deg, var(--gradient-left) 0%, var(--gradient-right) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 15rem;
    height: 2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

}

.contact_details_name_age_gradient {
    font-size: 1.5em;
    margin-top: 2rem;
    letter-spacing: 3px;
    font-weight: 500;
    background: linear-gradient(90deg, var(--gradient-left) 0%, var(--gradient-right) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    min-height: 2rem;
}

.contact_view_profile {
    position: relative;
    color: #ddd;
    letter-spacing: 5px;
    font-size: 1em;
    max-width: 10rem;
    max-height: 3rem;
    min-height: 3rem;
    overflow: clip;
    padding-top: 1rem;
    margin: 0 auto;
    text-transform: uppercase;
}

/* Add some padding inside the card container */

.container {
    padding: 2px 16px;
}

img {
    border-radius: 5px 5px 5px 5px;
    margin-bottom: 2px;
}

ol {
    text-align: center;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li {
    list-style-type: none;
    display: inline-block;
}

blockquote {
    font-weight: 100;
    font-size: 1rem;
    line-height: 1.4;
    position: relative;
    padding: 0.5rem;
    margin: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-family: "Special Elite", cursive;
    color: #6f6f80;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

blockquote:before, blockquote:after {
    position: absolute;
    color: #f1efe6;
    font-size: 6rem;
    width: 3rem;
    height: 3rem;
}

blockquote:before {
    content: "“";
    left: -4rem;
    top: -1rem;
}

blockquote:after {
    content: "”";
    right: -4rem;
    bottom: 0rem;
}

cite {
    line-height: 2;
    text-align: left;
}

.btn-grad {
    margin: 6px;
    padding: 6px 16px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    border-radius: 30px;
    display: block;
    border: none;
}

.btn-grad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.btn-grad-positive {
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #ffffff 100%);
}

.btn-grad-positive {
    margin: 6px;
    padding: 6px 16px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #444444;
    border-radius: 30px;
    display: block;
    border: #ccc;
    opacity: 0.9;
}

.btn-grad-positive:hover {
    background-position: right center;
    /* change the direction of the change here */
    text-decoration: none;
}

.btn-grad-black {
    background-image: linear-gradient(to right, #000000 0%, #202020 100%);
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #eeeeee;
    border-radius: 20px;
    border: #ccc;
    opacity: 0.8;
}

.btn-tab {
    margin: 0px;
    padding: 6px 16px;
    text-align: center;
    transition: 0.2s;
    background-size: 200% auto;
    color: white;
    border-radius: 12px 12px 0 0;
    display: block;
    border: none;
    box-shadow: 4px -4px 4px 0 rgb(0 0 0 / 10%);
    z-index: 5;
    background: var(--gradient-left);

}

.btn-tab-selected {
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #ffffff 100%);
    margin: 0px;
    padding: 6px 16px;
    text-align: center;
    transition: 0.2s;
    background-size: 200% auto;
    color: #111111;
    border-radius: 12px 12px 0 0;
    display: block;
    border: none;
    box-shadow: -4px -4px 4px 0 rgb(0 0 0 / 5%);
    z-index: 2;
}



.floating-btn {
    width: 70px;
    height: 70px;
    background: var(--gradient-left);
    text-decoration: none;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out;
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1;
}

.floating-btn:hover {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.floating-btn.clicked {
    background-color: #4169cc;
    outline: none;
}

.knowabouts {
    background: #F5F5F5;
    border-radius: 8px;
    padding: 1.5rem;
    color: #444444;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 2rem;
}

.slider-background {
    background: var(--bg-light);
    width: 100%;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-top: 0.5rem !important;
}

.MuiSlider-root {
    color: var(--gradient-right) !important;
}

.MuiAccordionDetails-root {
    padding: 0 0 0 0 !important;
}

.MuiAccordionSummary-root {
    padding: 0rem 1rem 0px 1rem !important;

}



@media only screen and (max-width:799px) {


    .MuiAccordionSummary-root {
        min-height: 32px !important;
        height: 32px !important;

    }


    .casino_cardbuttons {
        width: 40px;
        height: 60px;
        position: relative;
        top: 0rem;
    }

    .casino_cardnumber {
        position: absolute;
        top: 0.5rem;
        width: 100%;
        left: 3rem;
        text-align: left;
    }

    .casino {
        padding: 0rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
    }

    .casino_infobutton {

        top: 2.5rem;
    }


    .casino_cardbuttons_container {
        position: relative;
        z-index: 10;
        height: 3rem;
        width: 5rem;
        display: flex;
        align-content: center;
    }

    .profile_details_card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
        margin-top: 0rem;
    }

    .profile_details_back {

        color: #CCC;
        left: 2rem;
    }

    .main_container {
        padding-left: 0%;
        padding-right: 0%;
    }

    .header_slogan {
        font-size: 50px;
    }

    .main {
        margin-top: 0rem;
        margin-left: 0rem;
        margin-right: 0rem;
        border-radius: var(--border-radius);
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }

    .contact_list {
        padding: 0rem;
        padding-bottom: 1rem;
        border-radius: var(--border-radius);
        margin: 0rem;
    }

    .match_text_gradient {
        font-size: 0.75em;
    }

    .profile_add_contact_card {
        min-width: 5rem;
        height: 18rem;
        padding: 1rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin: 0.5rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }

    .profile_add_contact_card:hover {
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0);
    }

    .profile_card {
        min-width: 6rem;
        max-width: 8rem;
        min-height: 18rem;
        padding: 1.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin: 0.5rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }

    .profile_card:hover {
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0);
    }


    .profile_card_new_banner {
        font-size: 10px;
    }

    .contact_name_age_gradient {
        font-size: 1em;
        margin-top: 1rem;
        padding: 0.5rem;
        max-width: 8rem;

    }

    .empty_profile_card {
        min-width: 8rem;
        max-width: 8rem;
        height: 18rem;
        padding: 1.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin: 0.5rem;

    }

    .empty_profile_card:hover {
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0);
    }

    .contact_view_profile {
        margin-top: 0rem;
        font-size: 0.7em;
        letter-spacing: 2px;
        padding: 1rem;
        max-height: 2rem;
        min-height: 2rem;
        max-width: 6rem;
        overflow: clip;
    }

    .review_card {
        height: 14rem;
        margin: 0.5rem;

        padding: 1.5rem;

    }

    .review_card_title {
        font-size: 20px;
    }

    .review_card_description {
        font-size: 14px;

    }

    .welcome_text_card {
        max-width: 10rem;
        min-width: 10rem;
        padding: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;


        text-align: left;
        color: var(--black-color);
        font-size: 13px;


    }



}